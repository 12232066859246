import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const MySkills = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="skills" className="pt-10 pb-24">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair text-red font-semibold text-4xl mb-5">
            MY SKILLS
          </p>
          <LineGradient width="w-1/3" />
          <div className="mt-3 text-blue">
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              Javascript
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              Python
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              HTML
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              CSS
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              Java
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              C++
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              Tailwind
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              Django
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              Angular
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              React
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              SQL
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              MongoDB
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              Node
            </div>
            <div className="bg-grey inline-block rounded-md p-1.5 m-1.5">
              Git
            </div>
          </div>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
              before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"
            >
              <img
                alt="skills"
                className="z-10"
                src="assets/skills-image.png"
              />
            </div>
          ) : (
            <img alt="skills" className="z-10" src="assets/skills-image.png" />
          )}
        </div>
      </div>

      {/* SKILLS */}
      <p className="font-playfair text-yellow font-semibold text-4xl mb-3 mt-10">
        EXPERIENCES
      </p>
      <LineGradient width="w-1/3" />
      <div className="md:flex text-deep-blue md:justify-between mt-16 gap-32 mb-">
        {/* EXPERIENCE */}

        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">01</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Math-CS major at UCSD
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
            Currently a student at UCSD majot in Math-CS. Taken classes in data
            system, computer system programming, software engineering, web
            client langauge, etc. Self-learned Full Stack Engineering and
            developed a couple of projects along the way.
          </p>
        </motion.div>

        {/* INNOVATIVE */}
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">02</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Software Intern at Iqvia
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
            Developed user-friendly UI/UX interfaces with Salesforce LIghtning
            Components for a local pharmaceutical company's internal system and
            a client database with SQL tofaclitate data organization and
            retrieval. Achieved a 30% in user satisfaction.
          </p>
        </motion.div>
        {/* IMAGINATIVE */}
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">03</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Lab Research
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
            Developed a computer vision project using OpenCV library to
            construct 3D models from 2D images. Refactored key image processing
            algorithms and data manipulation routines from Python to C++,
            resulting in a significant reduction in execution time by roughly
            40%.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default MySkills;
